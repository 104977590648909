


































import Vue, { VueConstructor } from 'vue';
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';
import { StandardCategory } from '@/common/model/Category';
import { Edge } from '@frientrip/domain';
import { STANDARD_LEAVES } from './queries/query';
import { isHostAdmin } from '@/env';

interface CategorySearchResult {
  id: string;
  path: string;
}

interface StandardLeavesParam {
  page: number;
  size: number;
  filter: LeafCategoryFilter;
}

interface LeafCategoryFilter {
  likeLabelNameIncludePath?: string;
  rootCategoryId?: string | null;
}

export default (
  Vue as VueConstructor<Vue & { $refs: { [key: string]: HTMLFormElement } }>
).extend({
  name: 'StandardCategorySearch',
  components: {
    VueBootstrapTypeahead,
  },
  props: {
    root: {
      type: String,
    },
    value: {
      type: String,
    },
  },
  data() {
    return {
      text: '',
      rootCategoryId: this.root,
      categories: [],
      categoryPath: [],
      selected: { id: this.value, path: '' },
      page: 1,
      size: 10,
    };
  },
  watch: {
    root: {
      handler(newValue) {
        this.rootCategoryId = newValue;
        this.deleteSelectedCategory();
      },
    },
  },
  methods: {
    selectCategory(category: CategorySearchResult) {
      this.selected = category;
      this.text = '';
      this.categories = [];
      this.$emit('input', this.selected.id);
      this.$emit('select', category);
    },
    deleteSelectedCategory() {
      this.text = '';
      this.$refs.standardCategorySearch.inputValue = '';
      this.selected.id = '';
      this.selected.path = '';
      this.$emit('input', this.selected.id);
      this.$emit('select', this.selected);
    },
  },
  apollo: {
    categories: {
      query: STANDARD_LEAVES,
      variables(): StandardLeavesParam {
        return {
          page: this.page,
          size: this.size,
          filter: {
            likeLabelNameIncludePath: this.text,
            rootCategoryId:
              this.rootCategoryId !== '' ? this.rootCategoryId : null,
          },
        };
      },
      debounce: 200,
      update: data =>
        data.category.standardLeaves.edges
          .map((edge: Edge<StandardCategory>) => {
            let category: StandardCategory | undefined = edge.node;
            let path = [];
            while (category) {
              if (category.status === 'ACTIVE') {
                path.unshift(category.label.name);
              }
              category = category.parent;
            }
            path.shift(); // 최상위를 삭제, offline? online의 최상
            // 최상위 여행일 경우 하위 path를 없애더라도 id리턴하는 오류가 있으므로 조건에 따라 1dept를 없앰.
            // 최상위 레벨이 번개프립일경우 호스트 어드민에서는 조회할 수 없도록 한다.
            if (isHostAdmin() && path[0].includes('번개프립')) {
              return null;
            }
            if (path.length > 1) {
              return {
                id: edge.node.id,
                path: path.join(' > '),
              };
            } else {
              return {
                id: '',
                path: '',
              };
            }
          })
          .filter((item: { id: string; path: string }) => item),
      skip(): boolean {
        // graphql skip 조건
        return this.text === '';
      },
    },
  },
});
