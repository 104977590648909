







































































































































































































































import Vue from 'vue';
import { Select, Option } from 'element-ui';
import HostSearchInput from '@/components/Host/HostSearchInput.vue';
import StandardCategorySelect from '@/components/Category/StandardCategorySelect.vue';
import MerchandiserSearchInput from './Merchandiser/MerchandiserSearchInput.vue';
import {
  ProductFilter,
  ProductFilterQuery,
  ProductTermSearchType,
} from '../model/product/param/productSearchFilterParam';
import {
  ProductAttribute,
  ProductKindInfo,
  ProductState,
  ExposedChannel,
} from '../model/product/response/product';
import { ProductKind, ItemState, FripKind } from '../model/product/enum';
import {
  PRODUCT_ATTRIBUTES,
  PRODUCT_EXPOSED_CHANNELS,
  PRODUCT_KINDS,
} from '../queries/product/query';
import { isHostAdmin } from '@/env';
import { OptionType } from '@/common/model/Option';
import VueRouter from 'vue-router';
import { convertStringToProductState } from '../util/convertStringToProductState';

interface ProductAttributesParam {
  kind?: ProductKind | null;
}

interface ProductKindOptions extends OptionType {
  standardCategoryId: string;
}

export default Vue.extend({
  name: 'ProductSearchFilterForm',
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    StandardCategorySelect,
    HostSearchInput,
    MerchandiserSearchInput,
  },
  data(): {
    isAdmin: boolean;
    standardCategoryId: string;
    hideClosedProducts: boolean;
    filter: ProductFilter;
    totalCount: number;
    productSearchOptions: OptionType[];
    kindOptions: ProductKindOptions[];
    productAttributeOptions: OptionType[];
    exposedChannelOptions: OptionType[];
    termSearchTypeOptions: OptionType[];
    productStatusOptions: OptionType[];
    itemStatusOptions: OptionType[];
    fripKindOptions: OptionType[];
    isSearchDetailOpen: boolean;
  } {
    const query = this.$route.query as ProductFilterQuery;
    const titleLike = query.titleLike ? query.titleLike : '';

    let statusIn = isHostAdmin()
      ? [
          ProductState.EDITING,
          ProductState.READY,
          ProductState.SALE,
          ProductState.SOLD_OUT,
          ProductState.PAUSED,
          ProductState.APPLIED,
          ProductState.INSPECTING,
          ProductState.REJECTED,
          ProductState.SUSPENDED,
        ]
      : [
          ProductState.EDITING,
          ProductState.READY,
          ProductState.SALE,
          ProductState.SOLD_OUT,
          ProductState.PAUSED,
          ProductState.CLOSED,
          ProductState.TERMINATED,
          ProductState.APPLIED,
          ProductState.INSPECTING,
          ProductState.REJECTED,
          ProductState.SUSPENDED,
        ];
    if (query.statusIn) {
      if (typeof query.statusIn === 'string') {
        // TODO: 개선 필요
        statusIn = [query.statusIn as ProductState];
      } else {
        statusIn = query.statusIn.map(convertStringToProductState);
        console.log(statusIn);
      }
    }

    return {
      isAdmin: !isHostAdmin(),
      standardCategoryId: '',
      hideClosedProducts: true,
      filter: {
        hostId: null,
        managerId: null,
        ids: [],
        statusIn: statusIn,
        itemStatusIn: [],
        attributeIds: [],
        searchType: 'name',
        searchKeyword: titleLike,
        standardCategoryIds: [],
        exposedChannelIds: [],
        kind: null,
        fripKind: isHostAdmin() ? FripKind.COMMON : null,
        titleLike: titleLike,
        termWithType: {
          type: ProductTermSearchType.PRODUCT_CREATED,
          term: {
            startedAt: null,
            endedAt: null,
          },
        },
      },
      totalCount: 0,
      productSearchOptions: [
        { value: 'name', text: '상품명' },
        { value: 'id', text: '상품ID' },
      ],
      kindOptions: [],
      productAttributeOptions: [],
      exposedChannelOptions: [],
      termSearchTypeOptions: [
        { value: ProductTermSearchType.PRODUCT_CREATED, text: '상품등록일' },
        { value: ProductTermSearchType.SALE_START, text: '판매시작일' },
        { value: ProductTermSearchType.SALE_END, text: '판매종료일' },
        { value: ProductTermSearchType.PRODUCT_UPDATED, text: '상품수정일' },
      ],
      fripKindOptions: [
        { text: '일반프립', value: FripKind.COMMON },
        { text: '번개프립', value: FripKind.MEETUP },
      ],
      productStatusOptions: isHostAdmin()
        ? [
            { text: '등록중', value: ProductState.EDITING },
            { text: '판매대기', value: ProductState.READY },
            { text: '판매중', value: ProductState.SALE },
            { text: '품절', value: ProductState.SOLD_OUT },
            { text: '검수요청', value: ProductState.APPLIED },
            { text: '검수중', value: ProductState.INSPECTING },
            { text: '검수반려', value: ProductState.REJECTED },
            { text: '판매 일시중지', value: ProductState.PAUSED },
            { text: '판매중지', value: ProductState.SUSPENDED },
            { text: '판매종료', value: ProductState.CLOSED },
          ]
        : [
            { text: '등록중', value: ProductState.EDITING },
            { text: '판매대기', value: ProductState.READY },
            { text: '판매중', value: ProductState.SALE },
            { text: '품절', value: ProductState.SOLD_OUT },
            { text: '검수요청', value: ProductState.APPLIED },
            { text: '검수중', value: ProductState.INSPECTING },
            { text: '검수반려', value: ProductState.REJECTED },
            { text: '판매 일시중지', value: ProductState.PAUSED },
            { text: '판매중지', value: ProductState.SUSPENDED },
            { text: '판매종료', value: ProductState.CLOSED },
            { text: '운영종료', value: ProductState.TERMINATED },
          ],
      // 옵션 판매상태
      itemStatusOptions: [
        { text: '품절', value: ItemState.SOLD_OUT },
        { text: '판매가능', value: ItemState.OPENED },
      ],
      isSearchDetailOpen: false,
    };
  },
  computed: {
    FripKind() {
      return FripKind;
    },
    ProductKind() {
      return ProductKind;
    },
    searchDetailtoggleIcon(): string {
      if (!this.isSearchDetailOpen) {
        return 'iconDownArrow';
      } else {
        return 'iconTopArrow';
      }
    },
    defaultStatusIn(): ProductState[] {
      return isHostAdmin()
        ? [
            ProductState.EDITING,
            ProductState.READY,
            ProductState.SALE,
            ProductState.SOLD_OUT,
            ProductState.PAUSED,
            ProductState.APPLIED,
            ProductState.INSPECTING,
            ProductState.REJECTED,
            ProductState.SUSPENDED,
          ]
        : [
            ProductState.EDITING,
            ProductState.READY,
            ProductState.SALE,
            ProductState.SOLD_OUT,
            ProductState.PAUSED,
            ProductState.CLOSED,
            ProductState.TERMINATED,
            ProductState.APPLIED,
            ProductState.INSPECTING,
            ProductState.REJECTED,
            ProductState.SUSPENDED,
          ];
    },
    allStatusChecked: {
      get(): boolean {
        return this.filter.statusIn?.length === this.defaultStatusIn.length;
      },
      set(newValue: boolean) {
        if (newValue) {
          this.filter.statusIn = this.defaultStatusIn;
        } else if (
          this.filter.statusIn?.length === this.defaultStatusIn.length
        ) {
          this.filter.statusIn = [];
        }
      },
    },
    searchKeywordPlaceholder(): string {
      if (this.filter.searchType === 'name') {
        return '검색어를 입력해주세요';
      } else {
        return '상품 아이디를 , 로 구분해 입력해주세요.';
      }
    },
    rootStandardCategoryId(): string {
      const selectedProductKind = this.kindOptions.filter(
        kind => this.filter.kind === kind.value
      );
      if (selectedProductKind.length > 0) {
        return selectedProductKind[0].standardCategoryId;
      } else {
        return '';
      }
    },
  },
  watch: {
    'filter.kind': {
      handler() {
        this.filter.fripKind = null;
        this.filter.attributeIds = [];
      },
    },
  },
  methods: {
    resetFilter() {
      console.log('reset!');
      this.filter.hostId = null;
      this.filter.managerId = null;
      this.filter.statusIn = this.defaultStatusIn;
      this.filter.itemStatusIn = [];
      this.filter.fripKind = null;
      this.filter.standardCategoryIds = [];
      this.filter.kind = null;
      this.filter.exposedChannelIds = [];
      this.filter.attributeIds = [];
      this.filter.titleLike = null;
      this.filter.ids = [];
      this.filter.termWithType = {
        type: ProductTermSearchType.PRODUCT_CREATED,
        term: {
          startedAt: null,
          endedAt: null,
        },
      };
    },
    buildFilter() {
      const filter: ProductFilterQuery = {};

      if (this.filter.searchKeyword) {
        if (this.filter.searchType === 'name') {
          filter.titleLike = this.filter.searchKeyword;
        } else if (this.filter.searchType === 'id') {
          filter.ids = this.filter.searchKeyword
            .split(',')
            .map(id => id.trim())
            .filter(id => id !== '' && !isNaN(Number(id)));
        }
      }
      if (this.filter.hostId) {
        filter.hostId = this.filter.hostId;
      }

      if (this.filter.fripKind) {
        filter.fripKind = this.filter.fripKind;
      }

      if (this.filter.managerId) {
        filter.managerId = this.filter.managerId;
      }
      if (this.filter.kind) {
        filter.kind = this.filter.kind;
      }
      if (
        !this.allStatusChecked &&
        this.filter.statusIn &&
        this.filter.statusIn.length > 0
      ) {
        filter.statusIn = this.filter.statusIn;
      }

      if (this.filter.attributeIds && this.filter.attributeIds.length > 0) {
        filter.attributeIds = this.filter.attributeIds;
      }

      if (
        this.filter.exposedChannelIds &&
        this.filter.exposedChannelIds.length > 0
      ) {
        filter.exposedChannelIds = this.filter.exposedChannelIds;
      }

      if (
        this.filter.termWithType?.term.startedAt &&
        this.filter.termWithType?.term.endedAt
      ) {
        filter.termType = this.filter.termWithType.type;
        filter.startedAt = this.filter.termWithType.term.startedAt.toString();
        filter.endedAt = this.filter.termWithType.term.endedAt.toString();
      }

      if (this.standardCategoryId !== '') {
        filter.standardCategoryIds = [this.standardCategoryId];
      }

      if (this.filter.itemStatusIn && this.filter.itemStatusIn.length > 0) {
        filter.itemStatusIn = this.filter.itemStatusIn;
      }

      this.$router.push({ query: filter }).catch(err => {
        if (
          VueRouter.isNavigationFailure(
            err,
            VueRouter.NavigationFailureType.duplicated
          )
        ) {
          this.$emit('refresh', true);
        }
      });
    },
  },
  apollo: {
    kindOptions: {
      query: PRODUCT_KINDS,
      update: data =>
        data.product.kinds.map((kind: ProductKindInfo) => {
          return {
            value: kind.value,
            text: kind.label,
            standardCategoryId: kind.standardCategory.id,
          };
        }),
    },
    exposedChannelOptions: {
      query: PRODUCT_EXPOSED_CHANNELS,
      update: data =>
        data.product.exposedChannels.map((channel: ExposedChannel) => {
          return {
            value: channel.id,
            text: channel.name,
          };
        }),
    },
    productAttributeOptions: {
      query: PRODUCT_ATTRIBUTES,
      variables(): ProductAttributesParam {
        return {
          kind: this.filter.kind,
        };
      },
      update: data =>
        data.product.attributes.map((attribute: ProductAttribute) => {
          return {
            value: attribute.id,
            text: attribute.name,
          };
        }),
      skip(): boolean {
        if (!this.filter.kind || this.filter.kind === ProductKind.NONE) {
          return true;
        } else {
          return false;
        }
      },
    },
  },
});
