import { NormalizedCacheObject } from 'apollo-boost';
import ApolloClient from 'apollo-client';
import { ProductParam } from '../model/product/param/productParam';
import {
  CurriculumKind,
  CurriculumStyle,
  FripKind,
  InventoryTargetType,
  LevelOfDifficulty,
  ProductKind,
} from '../model/product/enum';
import {
  CREATE_PRODUCT,
  DELETE_PRODUCT,
  SAVE_PRODUCT,
  SAVE_PRODUCT_REFUND_POLICY,
} from '../queries/product/mutation';
import { ProductFormConvertService } from './ProductFormConvertService';
import { MutationService } from '@/common/service/MutationService';

export class ProductSaveService extends MutationService {
  private readonly productFormConvertService: ProductFormConvertService;

  constructor(
    apollo: ApolloClient<NormalizedCacheObject>,
    productFormConvertService: ProductFormConvertService
  ) {
    super(apollo);
    this.productFormConvertService = productFormConvertService;
  }

  public async createProduct(
    hostId: string,
    param: ProductParam
  ): Promise<string> {
    console.log('===create product!===');
    const productParam =
      this.productFormConvertService.convertFormToProductParam(param);
    const result = await this.apollo.mutate({
      mutation: CREATE_PRODUCT,
      variables: {
        hostId: hostId,
        param: productParam,
      },
    });
    return result.data?.createProduct.id;
  }

  public async saveProduct(id: string, param: ProductParam): Promise<string> {
    console.log('===save product!===');
    const productParam =
      this.productFormConvertService.convertFormToProductParam(param);
    const result = await this.apollo.mutate({
      mutation: SAVE_PRODUCT,
      variables: {
        id: id,
        param: productParam,
      },
    });
    console.log('===save product!e==');
    return result.data?.saveProduct.id;
  }

  public async deleteProduct(id: string): Promise<boolean> {
    console.log('===delete product!===');
    const result = await this.apollo.mutate({
      mutation: DELETE_PRODUCT,
      variables: {
        id: id,
      },
    });
    return result.data?.deleteProduct.success;
  }

  public async saveProductRefundPolicy(
    productId: string,
    refundPolicyId: string,
    extraNotice?: string
  ): Promise<string> {
    try {
      const result = await this.apollo.mutate({
        mutation: SAVE_PRODUCT_REFUND_POLICY,
        variables: {
          id: productId,
          param: { refundPolicyId: refundPolicyId, extraNotice: extraNotice },
        },
      });
      return result.data?.saveProductRefundPolicy;
    } catch (error) {
      throw new Error(`환불 정책 등록 실패: ${error}`);
    }
  }

  public getProductInitData(): ProductParam {
    return {
      title: '',
      catchphrase: '',
      cancelingRestricted: false,
      categories: [],
      attributeIds: [],
      exposedChannelIds: ['category', 'recommend', 'search'], // default로 전부 체크
      headerContents: [],
      htmlDetailContent: '',
      items: [],
      keywords: [],
      kind: ProductKind.NONE,
      managerId: null,
      notice: '',
      inquiryTemplateId: '',
      options: [
        {
          title: '',
          names: [],
        },
      ],
      productContentIds: [],
      saleTerm: {
        endedAt: 0,
        startedAt: new Date().valueOf(),
      },
      maximumPurchasableCount: 0, // 최대 주문 수량(0이면 제한 없음)
      standardCategoryId: '',
      externalProvisionIds: [],
      tagIds: [],
      underageRestricted: false,
      unverifiedUserRestricted: false,
      inventoryTargetOptionLevel: 0, // 옵션별 모집인원 제한일때 사용할 예정(현재 사용안함 default: 0)
      inventoryTargetType: InventoryTargetType.BY_ITEM,
      frip: {
        kind: FripKind.COMMON,
        attachedToSchedule: false,
        bookingConfirmationEnabled: true, // 수동 예약 확정이 기본값
        curriculum: {
          kind: CurriculumKind.SCHEDULE,
          sections: [],
          style: CurriculumStyle.NONE,
        },
        daysOfExpiration: 90,
        difficulty: LevelOfDifficulty.NONE,
        exclusions: [],
        inclusions: [],
        locationOfGathering: null,
        locationsOfVenue: [],
        plans: [],
        recommendedAge: 0,
        schedules: [],
        stuffsToPrepare: [],
      },
      grossPurchaseLimitation: {
        count: 0,
      },
    };
  }
}
