



































import Vue from 'vue';
import {
  CurriculumHourMinuteInput,
  CurriculumTimeTermInput,
} from '../../model/product/param/productAdditionalInfoParam';
export default Vue.extend({
  name: 'CurriculumTimeTermInput',
  props: {
    value: {
      type: Object,
    },
  },
  data() {
    return {
      term: {
        startingTime: {
          hour:
            this.value.startingTime.hour !== 0
              ? this.value.startingTime.hour
              : '',
          minute:
            this.value.startingTime.minute !== 0
              ? this.value.startingTime.minute
              : '',
        },
        endingTime: {
          hour:
            this.value.endingTime.hour !== 0 ? this.value.endingTime.hour : '',
          minute:
            this.value.endingTime.minute !== 0
              ? this.value.endingTime.minute
              : '',
        },
      },
      isValid: true,
    };
  },
  methods: {
    // TODO: validation 필요함
    validateInput() {
      let valid = true;
      valid =
        this.validateTime(this.term.startingTime) &&
        this.validateTime(this.term.endingTime) &&
        this.validateTerm(this.term);
      return valid;
    },
    validateTime(time: CurriculumHourMinuteInput) {
      if (time.hour >= 24 || time.hour < 0) {
        return false;
      }
      if (time.minute >= 60 || time.minute < 0) {
        return false;
      }
      return true;
    },
    validateTerm(term: CurriculumTimeTermInput) {
      const startedAt = term.startingTime.hour * 60 + term.startingTime.minute;
      const endedAt = term.endingTime.hour * 60 + term.endingTime.minute;
      return startedAt < endedAt;
    },
    changeTerm() {
      this.isValid = this.validateInput();

      if (this.isValid) {
        this.$emit('input', this.term);
      }
    },
  },
});
