import { StatusLabel } from '@/common/model/statusLabel';
import { ItemState } from '../model/product/enum';

export const getItemStatusLabel = (state: ItemState): StatusLabel => {
  switch (state) {
    case ItemState.EDITING:
      return {
        text: '등록중',
        color: 'primary',
        extraText: '',
      };
    case ItemState.OPENED:
      return {
        text: '판매중',
        color: 'primary',
        extraText: '',
      };
    case ItemState.SOLD_OUT:
      return {
        text: '품절',
        color: 'warning',
        extraText: '',
      };
    case ItemState.CLOSED:
      return {
        text: '판매중지',
        color: 'danger',
        extraText: '',
      };
    default:
      return {
        text: '',
        color: '',
        extraText: '',
      };
  }
};

export const getItemStatusLabelColor = (state: ItemState): string => {
  switch (state) {
    case ItemState.OPENED:
      return 'frip-primary';
    case ItemState.SOLD_OUT:
      return 'frip-warning';
    case ItemState.CLOSED:
      return 'frip-danger';
    default:
      return '';
  }
};
