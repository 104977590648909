import { Product, ProductKind } from '../model/product/response/product';
import { ProductParam } from '../model/product/param/productParam';
import {
  CurrencyCode,
  CurriculumKind,
  CurriculumStyle,
  FripKind,
  LevelOfDifficulty,
  ProductHeaderContentStyle,
} from '../model/product/enum';
import { FripLocation, FripPlan } from '../model/product/response/frip';
import { FripLocationParam } from '../model/product/param/productBasicInfoParam';
import { Item, ItemState } from '../model/product/response/item';
import {
  ItemParam,
  PlanParam,
  ScheduleParam,
} from '../model/product/param/productSaleInfoParam';
import {
  Curriculum,
  CurriculumSectionItem,
} from '../model/product/response/curriculum';
import {
  CurriculumParam,
  CurriculumSectionItemParam,
} from '../model/product/param/productAdditionalInfoParam';
import { Schedule } from '../model/product/response/schedule';
import { FixedTerm } from '@/common/model/FixedTerm';
import { ConvertService } from '@/common/service/ConvertService';
import { SCHEDULING_TERM_MONTH } from '../constants/scheduleConstant';
import { CommissionType } from '@frientrip/domain';

/**
 * Product API response -> ProductParam
 */
export class ProductConvertService extends ConvertService {
  public convertAPIResponseToParam(product: Product): ProductParam {
    return {
      id: product.id,
      title: product.title,
      status: product.status,
      catchphrase: product.catchphrase,
      cancelingRestricted: product.cancelingRestricted,
      categories: product.categories.map(category => {
        return {
          displayCategoryId: category.displayCategory.id,
          representative: category.representative,
        };
      }), // 전시 카테고리
      attributeIds: product.attributes.map(attribute => attribute.id),
      exposedChannelIds: product.exposures.map(exposure => exposure.id) || [],
      headerContents: product.headerContents.map(content => {
        return {
          contentId: content.content.id,
          url: content.content.uri,
          style: ProductHeaderContentStyle.SQUARE,
        };
      }),
      htmlDetailContent: product.contents[0]?.content || '',
      items: this.arrangeItems(
        product.items.map(item => this.convertItem(item))
      ),
      keywords: product.keywords,
      kind: product.kind,
      managerId: product.manager?.id,
      maximumPurchasableCount: product.maximumPurchasableCount,
      grossPurchaseLimitation: {
        count: product.grossPurchaseLimitation.count,
      },
      notice: product.notice,
      inquiryTemplateId: product.inquiryTemplate
        ? product.inquiryTemplate.id
        : '',
      options:
        product.options.length > 0
          ? product.options.map(option => {
              return {
                id: option.id,
                title: option.title,
                names: option.names,
              };
            })
          : [
              {
                title: '',
                names: [],
              },
            ],
      productContentIds: [],
      saleTerm: product.saleTerm
        ? {
            startedAt: product.saleTerm.startedAt || 0,
            endedAt: product.saleTerm.endedAt || 0,
          }
        : {
            startedAt: 0,
            endedAt: 0,
          },
      standardCategoryId:
        product.standardCategories.length > 0
          ? product.standardCategories[0].id
          : '',
      externalProvisionIds: product.externalProvisions.map(
        externalProvision => externalProvision.id
      ),
      tagIds: product.tags.map(tag => tag.id),
      underageRestricted: product.underageRestricted,
      unverifiedUserRestricted: product.unverifiedUserRestricted,
      inventoryTargetOptionLevel: product.inventoryTargetOptionsLevel || 0, // 옵션별 모집인원 제한일때 사용할 예정(현재 사용안함 default: 0)
      inventoryTargetType: product.inventoryTargetType,
      frip:
        product.kind !== ProductKind.GOODS && product.frip // frip은 nullable이므로 체크해줘야함
          ? {
              kind: product.frip.kind,
              attachedToSchedule: product.frip.attachedToSchedule,
              bookingConfirmationEnabled:
                product.frip.bookingConfirmationEnabled,
              plans: product.frip.plans.map(this.convertPlan),
              curriculum: this.convertCurriculum(product.frip.curriculum),
              daysOfExpiration: product.frip.daysOfExpiration,
              difficulty: product.frip.difficulty || LevelOfDifficulty.NONE,
              exclusions: product.frip.exclusions,
              inclusions: product.frip.inclusions,
              locationOfGathering: product.frip.locationOfGathering
                ? this.convertLocation(product.frip.locationOfGathering)
                : null,
              locationsOfVenue: product.frip.locationsOfVenue.map(
                this.convertLocation
              ),
              recommendedAge: product.frip.recommendedAge,
              schedulingTerm: this.getSchedulingTerm(),
              schedules: [],
              stuffsToPrepare: product.frip.stuffsToPrepare,
            }
          : {
              kind: FripKind.COMMON,
              attachedToSchedule: false,
              bookingConfirmationEnabled: true,
              curriculum: {
                kind: CurriculumKind.SCHEDULE,
                sections: [],
                style: CurriculumStyle.NONE,
              },
              daysOfExpiration: 90,
              difficulty: LevelOfDifficulty.NONE,
              exclusions: [],
              inclusions: [],
              locationOfGathering: null,
              locationsOfVenue: [],
              plans: [],
              recommendedAge: 0,
              schedules: [],
              stuffsToPrepare: [],
            },
    };
  }
  private getSchedulingTerm(): FixedTerm {
    const startedAt = new Date();
    const endDay = new Date();
    endDay.setMonth(endDay.getMonth() + SCHEDULING_TERM_MONTH);
    endDay.setDate(endDay.getDate() + 1); // 일단 3개월 뒤 다음날 오전 9시까지로 설정함
    return {
      startedAt: startedAt.getTime(),
      endedAt: endDay.getTime(),
    };
  }
  private arrangeItems(items: ItemParam[]): ItemParam[] {
    const closedItems: ItemParam[] = [];
    const activeItems: ItemParam[] = [];
    items.forEach((item: ItemParam) => {
      if (item.status === ItemState.CLOSED) {
        closedItems.push(item);
      } else if (item.status !== ItemState.TERMINATED) {
        activeItems.push(item);
      } else {
        // 판매 종료 아이템은 표시하지 않음
      }
    });
    return [...activeItems, ...closedItems];
  }
  private convertItem(item: Item): ItemParam {
    const param: ItemParam = {
      checked: item.status !== ItemState.CLOSED,
      id: item.id,
      absentFee: {
        bias: Number(item.absentFee.bias),
        charge: item.absentFee.charge,
        type: item.absentFee.type,
      },
      commission: {
        bias: Number(item.commission.bias),
        charge:
          item.commission.type === CommissionType.FIXED
            ? Number(item.commission.charge)
            : item.commission.charge,
        type: item.commission.type,
      },
      daysOfExpiration: item.daysOfExpiration,
      description: item.description,
      // inquiryTemplateId: '',
      maximumPurchasableCount: item.maximumPurchasableCount,
      minimumQuota: item.minimumQuota,
      quota: item.quota,
      name: item.name,
      options: item.options.map(option => {
        return {
          id: option.id,
          name: option.name,
          title: option.title,
        };
      }),
      paramId: item.id, // 수정 화면에서는 item의 id
      status: item.status,
      price: {
        currencyCode: item.price.currencyCode || CurrencyCode.KRW,
        discountRate: item.price.discountRate || 0,
        retail: Number(item.price.retail),
        sale: Number(item.price.sale),
      },
      representative: item.representative,
      saleTerm: {
        startedAt: item.saleTerm?.startedAt,
        endedAt: item.saleTerm?.endedAt,
      },
      salesType: item.salesType,
      scheduledBySale: item.scheduledBySale,
      ticketCount: item.ticketCount,
      digitalContent: {
        uri: item.digitalContent?.uri || '',
      },
    };
    return param;
  }
  private convertLocation(location: FripLocation): FripLocationParam {
    return {
      id: location.id,
      // kind: location.kind,
      label: location.label === '' ? location.address : location.label, // 만약 label이 없을시 주소로 대체함
      address: location.address,
      locationId: location.location?.id,
      geoPoint: {
        latitude: location.geoPoint.latitude,
        longitude: location.geoPoint.longitude,
      },
      overseas: location.overseas,
    };
  }
  private convertCurriculum(curriculum: Curriculum): CurriculumParam {
    return {
      kind: curriculum.kind,
      sections: curriculum.sections.map(section => {
        return {
          id: section.id,
          title: section.title,
          items: section.items.map(this.convertCurriculumSectionItem),
          etc: section.etc,
        };
      }),
      style: curriculum.style,
    };
  }
  private convertCurriculumSectionItem(
    item: CurriculumSectionItem
  ): CurriculumSectionItemParam {
    return {
      id: item.id,
      content: item.content,
      duration: item.duration,
      term: {
        startingTime: {
          hour: item.term.startingTime.hour,
          minute: item.term.startingTime.minute,
        },
        endingTime: {
          hour: item.term.endingTime.hour,
          minute: item.term.endingTime.minute,
        },
      },
      textTime: item.textTime,
    };
  }
  private convertPlan(plan: FripPlan): PlanParam {
    return {
      id: plan.id,
      appliedAllItems: plan.appliedAllItems,
      name: plan.name,
      minimumQuota: plan.minimumQuota,
      preparatoryPeriod: plan.preparatoryPeriod,
      quota: plan.quota,
      paramId: plan.id,
      overNight: plan.overNight,
      targetItemParamIds: plan.targetItems.map(item => item.id),
      term: {
        startedAt: plan.term.startedAt,
        endedAt: plan.term.endedAt,
      },
      times: plan.times.map(time => {
        return {
          starting: {
            hour: time.starting.hour,
            minute: time.starting.minute,
          },
          duration: time.duration,
        };
      }),
      weeks: plan.weeks,
    };
  }
  public convertSchedules(schedules: Schedule[]): ScheduleParam[] {
    return schedules.map(this.convertSchedule);
  }
  public convertSchedule(schedule: Schedule): ScheduleParam {
    return {
      id: schedule.id,
      appliedAllItems: false, // TODO: 이거 맞는지 확인 필요함 일단 현재는 저장하고 옵션을 또 추가하는 경우가 있으므로 이렇게 해놓음
      paramId: schedule.id,
      itemParamIds: schedule.items.map(item => item.id),
      maximumPurchasableCount: schedule.maximumPurchasableCount,
      minimumQuota: schedule.minimumQuota,
      quota: schedule.quota,
      planParamId: schedule.plan ? schedule.plan.id : null,
      saleTerm: {
        startedAt: schedule.saleTerm?.startedAt,
        endedAt: schedule.saleTerm?.endedAt,
      },
      term: {
        startedAt: schedule.term.startedAt,
        duration: schedule.term.duration,
      },
      title: schedule.title,
      edited: false,
      status: schedule.status,
    };
  }
}
