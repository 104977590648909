



















































































































































































































































































































import Vue from 'vue';
import CardSelect from '@/components/Forms/CardSelect.vue';
import CardCheckboxInput from '@/components/Forms/CardCheckboxInput.vue';
import Spinner from '@/components/Spinner.vue';
import HostSearchInput from '@/components/Host/HostSearchInput.vue';
import MerchandiserSearchInput from '../../Merchandiser/MerchandiserSearchInput.vue';
import StandardCategoryPath from '@/components/Category/StandardCategoryPath.vue';
import StandardCategorySearch from '@/components/Category/StandardCategorySearch.vue';
import StandardCategorySelect from '@/components/Category/StandardCategorySelect.vue';
import DisplayCategorySelect from '@/components/Category/DisplayCategorySelect.vue';
import Description from '@/components/Labels/Description.vue';
import draggable from 'vuedraggable';
import LocationAddForm from '../../Location/LocationAddForm.vue';
import LocationList from '../../Location/LocationList.vue';
import LocationSelect from '../../Location/LocationSelect.vue';
import Location from '../../Location/Location.vue';
import {
  ProductAttribute,
  ProductKindInfo,
  ExposedChannel,
} from '../../../model/product/response/product';
import {
  PRODUCT_KINDS,
  PRODUCT_ATTRIBUTES,
  PRODUCT_EXPOSED_CHANNELS,
} from '../../../queries/product/query';
import { Category } from '@/common/Category';
import {
  ProductKind,
  LocationKind,
  CategoryPrefix,
  InventoryTargetType,
  FripKind,
} from '../../../model/product/enum';
import {
  FripLocationParam,
  ProductCategoryParam,
} from '../../../model/product/param/productBasicInfoParam';
import {
  kindOptions,
  difficultyOptions,
  categorySearchOptions,
  fripKindOptions,
} from '../../../constants/productCreateOptions';
import { isHostAdmin } from '@/env';
import { PRODUCT_LOCATION_PRESETS } from '@/domain/product/queries/location/query';

interface LocationAddResult {
  kind: string;
  location: FripLocationParam;
}

interface ProductAttributesParam {
  kind: ProductKind;
}

interface LocationQueryParam {
  hostId: string;
}

export default Vue.extend({
  name: 'ProductBasicInfoForm',
  components: {
    HostSearchInput,
    MerchandiserSearchInput,
    Spinner,
    CardSelect,
    CardCheckboxInput,
    StandardCategoryPath,
    StandardCategorySearch,
    StandardCategorySelect,
    DisplayCategorySelect,
    Description,
    draggable,
    LocationAddForm,
    LocationList,
    LocationSelect,
    Location,
  },
  props: {
    product: {
      type: Object,
    },
    host: {
      type: Object,
    },
    manager: {
      type: Object,
    },
    isUpdateForm: {
      type: Boolean,
    },
    isCopy: {
      type: Boolean,
    },
    canEdit: {
      type: Boolean,
    },
    firstOpenedAt: {
      type: Number,
    },
  },
  data() {
    return {
      hostId: '',
      isAdmin: !isHostAdmin(),
      form: this.product,
      loading: 0,
      kind: this.product.kind,
      kindLoading: 0,
      locations: [],
      locationModal: false,
      locationAddModal: false,
      locationListModal: false,
      locationKind: LocationKind.VENUE,
      locationSelect: {
        label: '',
        kind: '',
      },
      provideUrl: false,
      categorySearchOption: this.isUpdateForm ? '' : 'select',
      attributeOptions: [],
      kindOptions: kindOptions,
      fripKindOptions: fripKindOptions,
      difficultyOptions: difficultyOptions,
      categorySearchOptions: categorySearchOptions,
      exposedChannelOptions: [],
    };
  },
  computed: {
    FripKind() {
      return FripKind;
    },
    isHostAvaiable(): boolean {
      return this.hostId !== '';
    },
    canEditKind(): boolean {
      return this.canEdit || this.isAdmin;
    },
    rootCategoryId(): string {
      const productKind = this.kindOptions.find(
        kind => this.form.kind === kind.value
      );

      if (productKind) return productKind.standardCategoryId;
      else return CategoryPrefix.OFFLINE.toString(); // TODO: 수정
    },
    showStandardCategoryForm(): boolean {
      return this.hostId !== '' && this.form.kind !== ProductKind.NONE;
    },
    showFripInfoForm(): boolean {
      return this.form.standardCategoryId !== '';
    },
    showLocationForm(): boolean {
      return this.form.kind === ProductKind.OFFLINE;
    },
    showMerchandiser(): boolean {
      return (
        this.isUpdateForm &&
        this.manager &&
        this.manager.id !== '' &&
        this.manager.id === this.form.managerId
      );
    },
    isFrip(): boolean {
      return this.form.kind !== ProductKind.GOODS;
    },
  },
  watch: {
    host: {
      immediate: true,
      handler(newHost) {
        this.hostId = newHost.id;
      },
    },
    hostId(newHostId: string) {
      this.form.frip.locationOfGathering = null;
      this.form.frip.locationsOfVenue = [];
      this.$emit('selectHost', newHostId);
    },
    'form.kind': {
      // 프립 유형 변경시 상품 속성, 표준 카테고리가 초기화 된다.
      handler(newValue: ProductKind) {
        this.form.attributeIds = [];
        this.form.standardCategoryId = '';
        if (newValue === ProductKind.GOODS) {
          this.form.frip.attachedToSchedule = false;
          this.form.inventoryTargetType = InventoryTargetType.BY_ITEM;
        }
        if (newValue !== ProductKind.OFFLINE) {
          // 오프라인 프립이 아닐 시에 장소는 초기화 된다.
          this.form.frip.locationsOfVenue = [];
          this.form.frip.locationOfGathering = null;
        }
      },
    },
  },
  methods: {
    selectStandardCategory(category: Category) {
      this.form.standardCategoryId = category.id;
      this.saveForm();
    },
    selectDisplayCategories(categories: ProductCategoryParam[]) {
      this.form.categories = categories;
    },
    selectFripKind(kind: string) {
      this.form.frip.kind = kind;
    },
    selectProductKind(kind: string) {
      if (kind === ProductKind.GOODS) {
        this.$modal.show(
          {
            message:
              '기존에 입력하신 옵션들과 일정이 초기화됩니다. 유형을 변경하시겠습니까?',
            showCancelButton: true,
          },
          () => {
            this.form.items = [];
            this.form.frip.daysOfExpiration = 90;
            this.form.frip.attachedToSchedule = false;
            this.form.frip.schedules = [];
            this.form.frip.plans = [];
            this.kind = kind;
          },
          () => {
            this.form.kind = this.kind;
          }
        );
      }
    },
    addLocation(locationResult: LocationAddResult) {
      if (locationResult.kind === LocationKind.VENUE) {
        this.addLocationOfVenue(locationResult.location);
      } else {
        this.setLocationOfGathering(locationResult.location);
      }
      this.closeLocationModal();

      // 장소 추가 시 리스트 재호출
      this.$apollo.queries.locations.refetch();
    },
    addLocationOfVenue(location: FripLocationParam) {
      const index = this.form.frip.locationsOfVenue.findIndex(
        (venue: FripLocationParam) => venue.locationId === location.locationId
      );
      if (index >= 0) {
        this.$modal.show({
          title: '진행장소 추가 불가',
          message: '이미 등록된 진행장소가 존재합니다.',
          type: 'warning',
        });
      } else if (this.form.frip.locationsOfVenue.length >= 5) {
        this.$modal.show({
          title: '진행장소 추가 불가',
          message: '진행장소는 최대 5개까지 등록할 수 있습니다.',
          type: 'warning',
        });
      } else {
        this.form.frip.locationsOfVenue.push(location);
        this.locationSelect = {
          label: location.label,
          kind: LocationKind.VENUE,
        };
        this.$notify({
          title: '진행장소 추가',
          message: '진행장소가 추가되었습니다.',
          type: 'success',
        });
      }
    },
    deleteLocation() {
      // 장소 삭제 시 리스트 재호출
      this.$apollo.queries.locations.refetch();
    },
    setLocationOfGathering(location: FripLocationParam) {
      this.form.frip.locationOfGathering = location;
      this.locationSelect = {
        label: location.label,
        kind: LocationKind.GATHERING,
      };
      this.saveForm();
      this.$notify({
        title: '집결장소 추가',
        message: '집결장소 추가되었습니다.',
        type: 'success',
      });
    },
    showLocationModal(kind: LocationKind) {
      this.locationKind = kind;
      this.locationListModal = false;
      this.locationAddModal = true;
    },
    showLocationList(kind: LocationKind) {
      this.locationKind = kind;
      this.locationAddModal = false;
      this.locationListModal = true;
    },
    closeLocationModal() {
      // this.locationModal = false;
      this.locationAddModal = false;
      this.locationListModal = false;
    },
    removeLocation(locationId: string) {
      const index = this.form.frip.locationsOfVenue.findIndex(
        (location: FripLocationParam) => location.locationId === locationId
      );
      if (index >= 0) {
        this.locationSelect = { label: '', kind: LocationKind.VENUE };
        this.form.frip.locationsOfVenue.splice(index, 1);
      }
    },
    removeLocationOfGathering() {
      this.locationSelect = { label: '', kind: LocationKind.GATHERING };
      this.form.frip.locationOfGathering = null;
    },
    saveForm() {
      let stringToReplace = this.form.title;
      // 특수문자 체크 패턴
      const pattern = /[^가-힣ㄱ-ㅎㅏ-ㅣa-zA-Z0-9]\\|\/|:|\*|"|'|>|<|\||%/gi;
      if (pattern.test(stringToReplace)) {
        stringToReplace = stringToReplace.replace(pattern, '');
      }
      if (stringToReplace != this.form.title) {
        alert(
          '상품 제목에 특수문자(|,>,<,\\,\',",/,*,:,%)를 사용할 수 없습니다.'
        );
        this.form.title = stringToReplace;
      }
      // 상위로 값을 전달하기 이전에 타이틀을 테스트한다.
      this.$emit('save', this.form);
    },
  },
  apollo: {
    kindOptions: {
      query: PRODUCT_KINDS,
      update: data =>
        data.product.kinds.map((kind: ProductKindInfo) => {
          return {
            value: kind.value,
            text: kind.label,
            description: kind.description,
            standardCategoryId: kind.standardCategory.id,
          };
        }),
      loadingKey: 'kindLoading',
    },
    attributeOptions: {
      query: PRODUCT_ATTRIBUTES,
      variables(): ProductAttributesParam {
        return {
          kind: this.form.kind,
        };
      },
      update: data =>
        data.product.attributes.map((attribute: ProductAttribute) => {
          if (
            isHostAdmin() &&
            (attribute.id === '14' ||
              attribute.id === '15' ||
              attribute.id === '16' ||
              attribute.id === '17' ||
              attribute.id === '18')
          ) {
            let returnValue = {
              value: attribute.id,
              description: attribute.description,
              text: attribute.name,
              disabled: true,
            };
            return returnValue;
          } else {
            let returnValue = {
              value: attribute.id,
              description: attribute.description,
              text: attribute.name,
            };
            return returnValue;
          }
        }),
      skip(): boolean {
        return this.form.kind === ProductKind.NONE;
      },
    },
    exposedChannelOptions: {
      query: PRODUCT_EXPOSED_CHANNELS,
      update: data =>
        data.product.exposedChannels.map((channel: ExposedChannel) => {
          return {
            value: channel.id,
            text: channel.name,
          };
        }),
      skip(): boolean {
        return !this.isAdmin;
      },
    },
    locations: {
      query: PRODUCT_LOCATION_PRESETS,
      variables(): LocationQueryParam {
        return {
          hostId: this.hostId,
        };
      },
      update: data => data.product.locationPresets,
      skip(): boolean {
        return this.hostId === '';
      },
    },
  },
});
